// src/ConfirmationModal.js
import React from 'react';
import './ConfirmationModal.css';

const ConfirmationModal = ({ showModal, onConfirm, onCancel }) => {
  if (!showModal) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>Are you sure you want to delete your account?</h3>
        <p>This action is irreversible.</p>
        <div className="modal-actions">
          <button className="modal-button cancel" onClick={onCancel}>
            Cancel
          </button>
          <button className="modal-button confirm" onClick={onConfirm}>
            Delete Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
