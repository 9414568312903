import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, OAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const appleSignIn = () => {
    const provider = new OAuthProvider('apple.com');
    signInWithPopup(auth, provider)
        .then((result) => {
            const user = result.user;
            console.log("Apple User:", user);
            // Navigate to the delete account page or wherever needed
        })
        .catch((error) => {
            console.error("Error during Apple sign-in:", error);
        });
};

const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
        .then((result) => {
            const user = result.user;
            console.log("Google User:", user);
            // Navigate to the delete account page or wherever needed
        })
        .catch((error) => {
            console.error("Error during Google sign-in:", error);
        });
};

export { auth, appleSignIn, googleSignIn };